<template>
  <div class="home-container">
    <!-- 子路由 -->
    <page-container class="home-tabs-page-container">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </page-container>

    <van-tabbar
        v-model="tabsActive"
        active-color="#3cc754"
        @change="activeOnChange"
    >
      <van-tabbar-item
          v-for="({ name, icon }, index) of tabsList"
          :key="name"
          @click="setActive(index)"
      >
        {{ name }}
        <template #icon="props">
          <van-image
              class="home-tabbar-img"
              v-show="props.active"
              :src="`img/home/${icon}-active.png`"
              fit="contain"
          />
          <van-image
              class="home-tabbar-img"
              v-show="!props.active"
              :src="`img/home/${icon}.png`"
              fit="contain"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <to-sign-in-tip/>
  </div>
</template>

<script>
import ToSignInTip from '@/views/signIn/toSignInTip';

export default {
  name: 'homeIndex',
  components: { ToSignInTip },
  data() {
    return {
      tabsActive: 0,
      tabsList: [
        {
          name: '首页',
          icon: 'home',
          routeName: 'homeHome',
          routePath: '/home/home',
          storeModuleName: 'homeHome',
        },
        {
          name: '任务',
          icon: 'mission',
          routeName: 'homeMission',
          routePath: '/home/mission',
          storeModuleName: 'homeMission',
        },
        {
          name: '课程',
          icon: 'course',
          routeName: 'homeCourse',
          routePath: '/home/course',
          storeModuleName: 'homeCourse',
        },
        {
          name: '我的',
          icon: 'my',
          routeName: 'homeMy',
          routePath: '/home/my',
          storeModuleName: 'homeMy',
        },
      ],
      isChangeActive: false, // 选中后再次点击判断变量
    };
  },
  computed: {
    tabsIndexList() {
      return this.tabsList.map(e => e.routePath);
    },
  },
  created() {
    this.tabsList.forEach(({ routeName }, index) => {
      if (this.$route.name === routeName)
        this.tabsActive = index;
    });
  },
  watch: {
    '$route'() {
      let homeRoutePathIndex = this.tabsIndexList.indexOf(this.$route.path);
      if (homeRoutePathIndex > -1 && this.tabsActive !== homeRoutePathIndex)
        this.tabsActive = homeRoutePathIndex;
    },
  },
  methods: {
    activeOnChange() {
      this.isChangeActive = true;
    },
    setActive(index) {
      this.isChangeActive = !this.isChangeActive;

      let { routePath, storeModuleName } = this.tabsList[index];
      if (this.isChangeActive) {
        // 改变页面对应store刷新状态变量
        if (storeModuleName) {
          this.$store.commit(`${storeModuleName}/setRefreshing`, true);
        }
      }

      this.$router.replace({
        path: routePath,
      });

      this.isChangeActive = false;
    },
  },
};
</script>

<style lang="less" scoped>
.home-container {
  height: 100vh;

  .home-tabs-page-container {
    bottom: @tabbar-height;
    overflow: hidden;
  }

  .van-tabbar {
    border-top: 1px solid #eaeaea;
  }

  @icon-size: 25px;
  /deep/ .van-tabbar-item__icon {
    height: @icon-size;

    img {
      height: @icon-size;
    }
  }

  .home-tabbar-img {
    width: @icon-size;
    height: @icon-size;
  }
}
</style>
